<template>
  <span class="icon">
    <font-awesome-icon v-if="fa" :icon="css" :size="size" />     
    <i :class="css" v-if="!fa"></i>  
  </span>
</template>
<style scoped>
svg.fa-instagram * {
  fill: url(#rg);
}
</style>
<script>
    import utils from 'utils'
    import InstagramIcon from './instagram.vue'
    export default{
      props: ['channel', 'size'],
      components: { InstagramIcon },
      computed: {
        "css": function() {
          let channel = this.channel.toLowerCase();
          return utils.faClasses(channel)
        },
         "fa": function() {
          return this.css.indexOf('custom') === -1
        }
      }
    }
</script>