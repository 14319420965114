var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post.img_src
    ? _c("div", { staticClass: "sb-post" }, [
        _c("article", [
          _c("section", [
            _c(
              "div",
              { staticClass: "sb-post-photo" },
              [
                !_vm.showEmbedVideo
                  ? _c(
                      "a",
                      {
                        staticClass: "sb-photo-link",
                        attrs: { href: _vm.post.url },
                        on: _vm._d({}, [
                          _vm.openInDialogEvent,
                          function ($event) {
                            $event.preventDefault()
                            return _vm.handler()
                          },
                        ]),
                      },
                      [
                        _vm.post.social_type == "Vimeo" || _vm.post.video_src
                          ? _c(
                              "div",
                              { staticClass: "sb-video-icon" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "play"] },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.lazy
                          ? _c("img", {
                              staticClass: "post-image not-so-lazy",
                              attrs: {
                                src: _vm.post.img_src,
                                alt: _vm.post.img_alt,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lazy && _vm.post.img_src
                          ? _c("v-lazy-image", {
                              staticClass: "post-image lazy",
                              attrs: { src: _vm.post.img_src },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showEmbedVideo && _vm.post.social_type == "Vimeo"
                  ? _c("vimeo", { attrs: { post: _vm.post } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showEmbedVideo && _vm.post.video_src,
                        expression: "showEmbedVideo && post.video_src",
                      },
                    ],
                  },
                  [
                    _c("video", {
                      ref: "video-" + _vm.post.id,
                      attrs: {
                        playsinline: "",
                        src: _vm.post.video_src,
                        type: "video/mp4",
                      },
                      on: { click: _vm.playVideo },
                    }),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }