<template>
  <vue-modality ref="myRef" title="" :hide-footer="true" width="900px" centered @hide="close">
   
  <div v-if="post" class="sb-modal-post">
      <you-tube v-if="post.social_type=='YouTube'" :post="post" />
      <div v-else>
        <div v-if="post.img_src!==''" class="photo">
          <a :href="post.url" 
             v-show="!showEmbedVideo"
             class="sb-photo-link"
             @[openInDialogEvent].prevent="handler()">
             <div class="sb-video-icon" 
                  v-if="post.social_type == 'Vimeo' || post.social_type == 'TikTok' || post.video_src">
              <font-awesome-icon :icon="['fas', 'play']" /> 
             </div>

            <img :src="post.img_src" 
                :alt="post.img_alt" 
                class="sb-modal-image" />
            </a>
            <vimeo v-if="showEmbedVideo && post.social_type == 'Vimeo'" :post="post"></vimeo>
            <div v-show="showEmbedVideo && post.video_src">
              <video :ref="'video-' + post.id" 
                      playsinline 
                      :src="post.video_src" 
                      @click="playVideo"
                      type='video/mp4'></video>
            </div>
        </div>
        <div v-else class="sb-modal-post-body-no-photo">
          <a :href="post.url">           
            <strong v-if="post.title">{{post.title}}</strong>   
            <div class="sb-post-description" v-html="post.description"></div>
          </a>
        </div>
      </div>

      <div class="sb-content">  
        <div class="sb-modal-author-container">
          <div v-if="post.author_img_src" class="sb-modal-avatar">
              <img :src="post.author_img_src" v-if="post.author_img_src" />
            </div>
          <div class="sb-modal-author" 
                :class="{'sb-no-profile-image': !post.author_img_src }">
                <a :href="post.author_url">
                  {{post.author}}
                </a>
          </div>
        </div>
          
        <div v-if="post.img_src !== ''"
             class="sb-modal-post-body">
          <a :href="post.url">
              <strong v-if="post.title">{{post.title}}</strong>   
              <div class="sb-post-description" v-html="post.description"></div>
          </a>
        </div>

      <div class="sb-modal-view">
        <a :href="post.url" 
            class="sb-channel-button">
            <icon :channel="post.social_type" :size="'2x'" />
        </a>
      </div>

      <div class="sb-modal-activity">
          <ul class="sb-modal-meta-list">
              <li class="likes" v-if="post.likes>0">
                  <font-awesome-icon :icon="['far', 'thumbs-up']" /> <a aria-label="Likes" :href="post.url">{{post.likes}}</a>
              </li>
              <li class="comments" v-if="post.comments>0">
                  <font-awesome-icon :icon="['far', 'comments']" /> <a aria-label="Comments" :href="post.url">{{post.comments}}</a>
              </li>
          </ul>
      </div>

      <div v-if="post.published" class="sb-modal-published">
        <timeago :datetime="post.published"></timeago>
      </div>

      <div class="sb-navigation">
        <a class="sb-navigation-button" v-if="navigation.prev" @click="prev">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </a>
        <a class="sb-navigation-button" v-if="navigation.next" @click="next">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />    
        </a>
      </div>

    </div>
  </div>
  </vue-modality>
</template>
<style scoped>
.vm-header {
  padding: 0;
}
.vm-body {
    padding: 10px 0;
}
</style>
<script>   
    import { mapState, mapActions } from 'vuex'
    import VueModality from 'vue-modality'
    import Icon from 'components/shared/icons/icon.vue'
    import YouTube from './modal/youtube.vue'
    import Vimeo from 'components/shared/post/video/vimeo.vue'

    export default{
        props: ['icons'],
        components: { VueModality, Icon, YouTube, Vimeo },
        data() {
            return {
                showEmbedVideo: false,
            }
        },
        computed: {
           ...mapState("feed", ["posts", "navigation"]),
           post:  function() {
               return this.navigation.active
           },
           openInDialogEvent: function() {
               if(this.post.social_type == 'Vimeo') 
               {
                   return "click"
               }
               if(this.post.video_src) 
               {
                  return "click"
               }
               return "";
           }
        },
        watch: {
          post: function(post, old) {
            console.log("Show modal changed from " + old + " to " + post)
            if(post)
            {
              this.$refs.myRef.open() 
            }
          }
        },
        methods: {  
          ...mapActions("feed", ['setNavigation']),
          prev() {
            this.setNavigation(this.navigation.prev)
          },
          next() {
            this.setNavigation(this.navigation.next)
          },
          close() {
            this.setNavigation(null)
          },
          handler() {
            if(this.post.social_type == 'Vimeo') 
            {
                this.showEmbedVideo = true
                return;
            }
            if(this.post.video_src) 
            {
                this.showEmbedVideo = true
                let video = this.$refs['video-' + this.post.id]
                video.play();
                return;
            }
            alert(0);
          },
          playVideo() {
            let video = this.$refs['video-' + this.post.id]
            if(!video.paused)
            {
                video.pause()
            }
            else
            {
                video.play()
            }
          }
      }
    }
</script>