var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty-container" }, [
      _c("div", { staticClass: "text-center my-5" }, [
        _vm._v("\n        No posts found.\n    "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }