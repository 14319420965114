import getters from './feed/getters'
import mutations from './feed/mutations'
import actions from './feed/actions'

const state = {
    properties: {},
    sources: {},
    filter_sources: {},
    organisation: {},
    posts: {
      type: Array,
      default: () => ([])
    },
    social_connections: {},
    pagination: {},
    navigation: {},
    post:{}
}

export const feed = {
  namespaced: true,
  state,
  getters,
  actions, 
  mutations
}