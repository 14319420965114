<template>        
    <div class="sb-post">
        <article>
            <header v-if="post.img_src">
                <div class="sb-post-photo">
                    <a v-if="!showEmbedVideo" 
                       :href="post.url" 
                       class="sb-photo-link"
                       @[openInDialogEvent].prevent="handler()">
                        <div class="sb-video-icon" 
                            v-if="post.social_type == 'Vimeo' || post.video_src">
                            <font-awesome-icon :icon="['fas', 'play']" /> 
                        </div>
                        <img v-if="!lazy"
                            :src="post.img_src" 
                            :alt="post.img_alt" 
                            class="post-image not-so-lazy" />
                        <v-lazy-image v-if="lazy && post.img_src" 
                                    :src="post.img_src" 
                                    class="post-image lazy" />
                     </a>
                     <vimeo v-if="showEmbedVideo && post.social_type == 'Vimeo'" :post="post"></vimeo>
                     <div v-show="showEmbedVideo && post.video_src">
                        <video :ref="'video-' + post.id" 
                               playsinline 
                               :src="post.video_src" 
                               @click="playVideo"
                               type='video/mp4'></video>
                     </div>
                </div>
            </header>
            <section v-if="properties.show_author">
                <div class="sb-post-author">
                    <a :href="post.author_url">
                        <div v-if="post.author_img_src" class="avatar">
                            <img :src="post.author_img_src" v-if="post.author_img_src" />
                        </div>
                        <div class="sb-author" :class="{'sb-no-profile-image': !post.author_img_src }">{{post.author}}</div>
                        <div v-if="post.published" class="sb-post-published">
                            <timeago :datetime="post.published"></timeago>
                        </div>
                    </a>
                </div>
            </section>
            <section v-if="properties.show_description && (post.description || post.title)">
            <div class="sb-post-body">
                <strong v-if="post.title">{{post.title}}</strong> 
                <div class="sb-post-description" v-html="post.description"></div>
                <a :href="post.url" @[openInDialogEvent].prevent="dialog()"></a>
            </div>
            </section>
            <footer v-if="post.social_type && properties.show_meta">
                <div class="sb-post-meta">
                    <div class="sb-activity">
                        <ul class="sb-meta-list">
                            <li class="likes" v-if="post.likes>0">
                                <font-awesome-icon :icon="['far', 'thumbs-up']" /> <a aria-label="Likes" :href="post.url">{{post.likes}}</a>
                            </li>
                            <li class="comments" v-if="post.comments>0">
                                <font-awesome-icon :icon="['far', 'comments']" /> <a aria-label="Comments" :href="post.url">{{post.comments}}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="sb-channel">
                        <icon :channel="post.social_type" :size="'lg'" />
                    </div>
                </div>
            </footer>
        </article>
    </div>  
</template>
<script>
    import Vue from 'vue'
    import { mapState, mapActions } from 'vuex'
    import VLazyImage from "v-lazy-image"
    import Icon from 'components/shared/icons/icon.vue'
    import Vimeo from 'components/shared/post/video/vimeo.vue'
 
    export default{
        props: ["properties", "post", "lazy"],
        components: { VLazyImage, Icon, Vimeo },
        data() {
            return {
                showEmbedVideo: false,
            }
        },
        computed: {
           openInDialogEvent: function() {
               if(this.properties.open_posts_in_dialog == 1) 
               {
                   return "click"
               }
               if(this.post.social_type == 'Vimeo') 
               {
                   return "click"
               }
               if(this.post.video_src) 
               {
                   return "click"
               }
               return null
           }
        },
        methods: {
            ...mapActions("feed", ['setNavigation']),
            handler() {
                console.log("handler!!")
                if(this.properties.open_posts_in_dialog == 1) 
                {
                    this.setNavigation(this.post)
                    return;
                }
                if(this.post.social_type == 'Vimeo') 
                {
                    this.showEmbedVideo = true
                    return;
                }
                if(this.post.video_src) 
                {
                    this.showEmbedVideo = true
                    let video = this.$refs['video-' + this.post.id]
                    video.play();
                    return;
                }
            },
            playVideo() {
                let video = this.$refs['video-' + this.post.id]
                if(!video.paused)
                {
                    video.pause()
                }
                else
                {
                    video.play()
                }
            }
        }
    }
</script>