var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sb-post-container" },
    [
      _vm.properties.theme == "default"
        ? _c("default-theme", {
            key: _vm.post.id,
            attrs: {
              properties: _vm.properties,
              post: _vm.post,
              lazy: _vm.properties.lazy_load_images,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.track(_vm.post.id)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.theme == "hoverable"
        ? _c("hoverable", {
            key: _vm.post.id,
            attrs: {
              properties: _vm.properties,
              post: _vm.post,
              lazy: _vm.properties.lazy_load_images,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.track(_vm.post.id)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.theme == "dark"
        ? _c("dark", {
            key: _vm.post.id,
            attrs: {
              properties: _vm.properties,
              post: _vm.post,
              lazy: _vm.properties.lazy_load_images,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.track(_vm.post.id)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.theme == "polaroid"
        ? _c("polaroid", {
            key: _vm.post.id,
            attrs: {
              properties: _vm.properties,
              post: _vm.post,
              lazy: _vm.properties.lazy_load_images,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.track(_vm.post.id)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.theme == "imagegrid"
        ? _c("image-grid", {
            key: _vm.post.id,
            attrs: {
              properties: _vm.properties,
              post: _vm.post,
              lazy: _vm.properties.lazy_load_images,
            },
            nativeOn: {
              click: function ($event) {
                return _vm.track(_vm.post.id)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }