var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { width: "0", height: "0" } },
    [
      _c(
        "radialGradient",
        { attrs: { id: "rg", r: "150%", cx: "30%", cy: "107%" } },
        [
          _c("stop", { attrs: { "stop-color": "#fdf497", offset: "0" } }),
          _vm._v(" "),
          _c("stop", { attrs: { "stop-color": "#fdf497", offset: "0.05" } }),
          _vm._v(" "),
          _c("stop", { attrs: { "stop-color": "#fd5949", offset: "0.45" } }),
          _vm._v(" "),
          _c("stop", { attrs: { "stop-color": "#d6249f", offset: "0.6" } }),
          _vm._v(" "),
          _c("stop", { attrs: { "stop-color": "#285AEB", offset: "0.9" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }