<template>  
    <div class="sb-post-container">        
        <default-theme :properties="properties" 
                       :post="post"
                       v-if="properties.theme=='default'"
                       :key="post.id"  
                       :lazy="properties.lazy_load_images"
                       @click.native="track(post.id)"
        />  
        <hoverable :properties="properties" 
                       :post="post"
                       v-if="properties.theme=='hoverable'"
                       :key="post.id"
                       :lazy="properties.lazy_load_images"
                       @click.native="track(post.id)"
        />  
        <dark :properties="properties" 
                       :post="post"
                       v-if="properties.theme=='dark'"
                       :key="post.id"
                       :lazy="properties.lazy_load_images"
                       @click.native="track(post.id)"
        />  
        <polaroid :properties="properties" 
                  :post="post"
                  v-if="properties.theme=='polaroid'"
                  :key="post.id"
                  :lazy="properties.lazy_load_images"
                  @click.native="track(post.id)"
        />    
        <image-grid :properties="properties" 
                    :post="post"
                    v-if="properties.theme=='imagegrid'"
                    :key="post.id"
                    :lazy="properties.lazy_load_images"
                    @click.native="track(post.id)"
        />     
    </div>       
</template>
<style scoped>
</style>
<script>
    import Vue from 'vue'
    import { mapState } from 'vuex' 
    import utils from 'utils'
    import DefaultTheme from 'components/shared/post/themes/default-theme.vue'
    import Hoverable from 'components/shared/post/themes/hoverable.vue'
    import Dark from 'components/shared/post/themes/dark.vue'
    import Polaroid from 'components/shared/post/themes/polaroid.vue'
    import ImageGrid from 'components/shared/post/themes/image-grid.vue'
    import axios from 'axios'

    export default{
        props: ["post"],
        created: function () {  
            if(this.post.description && 
               utils.isNumeric(this.properties.trim_description) &&
               this.post.description.length > this.properties.trim_description)
            {
                this.post.description = this.post.description.substr(0, this.properties.trim_description) + ".."
            }
        },
        computed: {
           ...mapState("feed", ["properties"]),
        },
        components: {
            DefaultTheme,
            Dark,
            Hoverable,
            Polaroid,
            ImageGrid
        },
        methods:
        {  
            track: function(id)
            { 
                axios.get(utils.apiHost() + '/api/post/click/' + id)
            }
        }
    }
</script>