<template>          
    <div class="sb-loader">Loading...</div>         
</template>
<style scoped>
.sb-loader{
    height: 200px;
}
</style>

<script>
    export default{
    }
</script>