<template>
    <div class="empty-container">
        <div class="text-center my-5">
            No posts found.
        </div>
    </div>
</template>
<style scoped>
 .empty-container{
     font-weight: 600;
     font-size: 1.25rem;
 }
</style>
<script>
    export default{
    }
</script>