<template>
  <iframe v-if="post.social_type=='YouTube'" width="560" height="315" 
          :src="youTubeEmbedUrl" 
          frameborder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen></iframe>
</template>
<script>   
    export default{
        props: ['post'],
        computed: {
           youTubeEmbedUrl: function() {
             if(this.post.social_type !== "YouTube")
             {
               return "";
             }
             return this.post.url.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")
           }
        }
    }
</script>