var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post.social_type == "YouTube"
    ? _c("iframe", {
        attrs: {
          width: "560",
          height: "315",
          src: _vm.youTubeEmbedUrl,
          frameborder: "0",
          allow:
            "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
          allowfullscreen: "",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }