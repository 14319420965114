import Vue from 'vue'
import Vuex from 'vuex'
import { feed } from './modules/feed'

Vue.use(Vuex)

const state = {
    feed: {}
}

export const store = new Vuex.Store({
  modules: {
    feed: feed
  },
  state
})