var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sb-post" }, [
    _c("article", [
      _vm.post.img_src
        ? _c("header", [
            _c(
              "div",
              { staticClass: "sb-post-photo" },
              [
                !_vm.showEmbedVideo
                  ? _c(
                      "a",
                      {
                        staticClass: "sb-photo-link",
                        attrs: { href: _vm.post.url },
                        on: _vm._d({}, [
                          _vm.openInDialogEvent,
                          function ($event) {
                            $event.preventDefault()
                            return _vm.handler()
                          },
                        ]),
                      },
                      [
                        _vm.post.social_type == "Vimeo" || _vm.post.video_src
                          ? _c(
                              "div",
                              { staticClass: "sb-video-icon" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["fas", "play"] },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.lazy
                          ? _c("img", {
                              staticClass: "post-image not-so-lazy",
                              attrs: {
                                src: _vm.post.img_src,
                                alt: _vm.post.img_alt,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.lazy && _vm.post.img_src
                          ? _c("v-lazy-image", {
                              staticClass: "post-image lazy",
                              attrs: { src: _vm.post.img_src },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showEmbedVideo && _vm.post.social_type == "Vimeo"
                  ? _c("vimeo", { attrs: { post: _vm.post } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showEmbedVideo && _vm.post.video_src,
                        expression: "showEmbedVideo && post.video_src",
                      },
                    ],
                  },
                  [
                    _c("video", {
                      ref: "video-" + _vm.post.id,
                      attrs: {
                        playsinline: "",
                        src: _vm.post.video_src,
                        type: "video/mp4",
                      },
                      on: { click: _vm.playVideo },
                    }),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.show_author
        ? _c("section", [
            _c("div", { staticClass: "sb-post-author" }, [
              _c("a", { attrs: { href: _vm.post.author_url } }, [
                _vm.post.author_img_src
                  ? _c("div", { staticClass: "avatar" }, [
                      _vm.post.author_img_src
                        ? _c("img", { attrs: { src: _vm.post.author_img_src } })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sb-author",
                    class: { "sb-no-profile-image": !_vm.post.author_img_src },
                  },
                  [_vm._v(_vm._s(_vm.post.author))]
                ),
                _vm._v(" "),
                _vm.post.published
                  ? _c(
                      "div",
                      { staticClass: "sb-post-published" },
                      [
                        _c("timeago", {
                          attrs: { datetime: _vm.post.published },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.properties.show_description &&
      (_vm.post.description || _vm.post.title)
        ? _c("section", [
            _c("div", { staticClass: "sb-post-body" }, [
              _vm.post.title
                ? _c("strong", [_vm._v(_vm._s(_vm.post.title))])
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "sb-post-description",
                domProps: { innerHTML: _vm._s(_vm.post.description) },
              }),
              _vm._v(" "),
              _c("a", {
                attrs: { href: _vm.post.url },
                on: _vm._d({}, [
                  _vm.openInDialogEvent,
                  function ($event) {
                    $event.preventDefault()
                    return _vm.dialog()
                  },
                ]),
              }),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.post.social_type && _vm.properties.show_meta
        ? _c("footer", [
            _c("div", { staticClass: "sb-post-meta" }, [
              _c("div", { staticClass: "sb-activity" }, [
                _c("ul", { staticClass: "sb-meta-list" }, [
                  _vm.post.likes > 0
                    ? _c(
                        "li",
                        { staticClass: "likes" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "thumbs-up"] },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                "aria-label": "Likes",
                                href: _vm.post.url,
                              },
                            },
                            [_vm._v(_vm._s(_vm.post.likes))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.post.comments > 0
                    ? _c(
                        "li",
                        { staticClass: "comments" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["far", "comments"] },
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              attrs: {
                                "aria-label": "Comments",
                                href: _vm.post.url,
                              },
                            },
                            [_vm._v(_vm._s(_vm.post.comments))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sb-channel" },
                [
                  _c("icon", {
                    attrs: { channel: _vm.post.social_type, size: "lg" },
                  }),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }