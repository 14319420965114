<template>   
    <div class="sb-filter-container">    
        <div class="sb-filter-dropdown-container" v-if="properties.filters_as=='dropdown'">   
            <select class="sb-filter-dropdown" v-model="filter" @change="filterPosts">
                <option value=""></option>
                <option v-for="(opt, index) in filterOptions" :key="index" :value="opt.value">
                    {{opt.display}}
                </option>
            </select>  
        </div>   
        <div v-if="properties.filters_as=='icons'" class="sb-filter-pills"> 
            <button type="button"
                     class="sb-filter-button" 
                     :class="{'active' : ''==filter}"
                     @click="setFilter(''); filterPosts()" >
                <span class="label">Show All</span>
            </button>
            <button type="button" 
                    v-for="(opt, index) in filterOptions" 
                    class="sb-filter-button" 
                    :class="{'active' :opt.value==filter}"
                    :key="index"
                    @click="setFilter(opt.value); filterPosts()">
                <icon :channel="opt.css" :size="'lg'" />
                <span class="label">{{opt.display}}</span>
            </button>  
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
    import Vue from 'vue'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex' 
    import utils from 'utils'
    import Icon from 'components/shared/icons/icon.vue'

    export default{
        data() {
            return {
                filter: ""
            }
        },
        components: { Icon },
        computed: {
            ...mapState("feed", ["properties", "filter_sources"]),
            filterOptions: function () {
                let options = [];
                if(this.properties.filters == "type") {
                    
                    let sources = [...new Set(this.filter_sources.map(s => s.channel))].sort()
                   
                    sources.forEach(source => {
                        let channel = source.toLowerCase();
                        options.push({"value": source, "display": source, "css": channel })
                    });
                }

                if(this.properties.filters == "channel") {
                    this.filter_sources.forEach(element => {
                        let channel = element.channel.toLowerCase();
                        let exists = options.some(item => item.value === element.title);
                        if(!exists) {
                             options.push({"value": element.title, "display": element.nickname, "css": channel })
                        }
                    });
                }
                return options
            }
        },
        methods: {
            filterPosts() {  
                this.$emit('filterPosts', this.filter)
            },
            setFilter(val) {
                console.log("set filter", val)
                this.filter = val
            }
        }
    }
</script>