import axios from 'axios'
import utils from 'utils'

axios.defaults.headers.common['Content-Type'] = 'application/json'

const setNavigation = (context, activePost) => {
  let active = context.state.posts.indexOf(activePost)
  let next = active+1 > context.state.posts.length ? 0 : active+1
  let nextPost = context.state.posts[next]
  let prev = active-1 < 0 ? context.state.posts.length : active-1
  let prevPost = context.state.posts[prev]
  context.commit('SET_NAVIGATION', { 'active': activePost, 'next': nextPost, 'prev': prevPost})
} 

const setPost = (context, post) => {
  context.commit('SET_POST', post)
} 

const getFeed = (context, payload) => {
  let url = utils.apiHost() + "/api/feed/" + payload.id

  let params = []
  if(payload.page_size) { params.push("page_size=" + payload.page_size) }
  if(payload.max_pages) { params.push("max_pages=" + payload.max_pages) }
  if(payload.start) { params.push("start=" + payload.start) }
  if(payload.end) { params.push("end=" + payload.end) }
  if(payload.filters) { params.push("filters=" + payload.filters) }
  if(payload.filter_value) { params.push("filter_value=" + payload.filter_value) }
  if(payload.theme) { params.push("theme=" + payload.theme) }
  if(payload.trim_description) { params.push("trim_description=" + payload.trim_description) }
  if(params.length > 0 ) { url += "?" + params.join("&") }

  axios.get(url)
       .then((response)=>{
          context.commit('SET_FEED', response.data)
        })
} 

const getFeedPosts = (context, payload) => {
  let id = context.state.properties.id
  let page = payload.page ? payload.page : 1
  let url = utils.apiHost() + '/api/feed/posts/' + id

  let params = []
  params.push("page_size=" + context.state.properties.page_size )
  if(context.state.properties.max_pages) { params.push("max_pages=" + context.state.properties.max_pages ) }
  if(context.state.properties.start) { params.push("start=" + context.state.properties.start ) }
  if(context.state.properties.end) { params.push("end=" + context.state.properties.end ) }
  params.push("theme=" + context.state.properties.theme )
  params.push("trim_description=" + context.state.properties.trim_description ) 
  if(payload.filters) { params.push("filters=" + payload.filters) }
  if(payload.filter_value) { params.push("filter_value=" + payload.filter_value) }
  params.push("page=" + page)
  if(params.length > 0 ) { url += "?" + params.join("&") }
  
  axios.get(url)
        .then((response)=>{
          context.commit('SET_CURRENT_PAGE', { page: page })
          context.commit('SET_POSTS', response.data.posts )
          context.commit('SET_PAGINATION', response.data.pagination )
        })
} 

export default {
  getFeed, 
  getFeedPosts,
  setNavigation,
  setPost
}