<template>
    <vimeo-player ref="vimeo"
                  @ready="onReady"
                  v-bind="playerOptions"></vimeo-player>
</template>
<script>
    import Vue from 'vue'
    import { vueVimeoPlayer } from 'vue-vimeo-player'
 
    export default{
        props: ["post", "play"],
        components: { VimeoPlayer: vueVimeoPlayer },
        data() {
            return {
                playerOptions: {
                    videoId: this.post.url.substring(this.post.url.lastIndexOf("/")+1),
                    playerWidth: undefined,
                    playerHeight: undefined,
                    options: {
                        responsive: true
                    }
                }
            }
        },
        watch: {
          post: function(post, old) {
            this.$refs.vimeo.pause()
          }
        },
        methods: {
            onReady() {
                this.$refs.vimeo.play()
            }
        }
    }
</script>