<template>  
    <div class="noposts-container">  
        <p class="lead">You feed is empty.</p>
        <p>Please add some sources in the <a :href="webHost() + '/dashboard'" target="_blank">dashboard</a>.</p>
    </div>       
</template>
<style scoped>

    .noposts-container
    {
        padding: 1rem;
    }

    p {
        font-family: 'Lato', sans-serif;
    }

    .lead { 
        font-size: 1.2rem;
        font-weight: 600;
    }

</style>
<script>
    import utils from 'utils'
    export default {
        methods: {
            webHost() {
                return "https://socialblend.com";
                return isProduction() ? "https://socialblend.com" : "http://socialblend.test";
            }
        }
    }
</script>