var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "icon" },
    [
      _vm.fa
        ? _c("font-awesome-icon", { attrs: { icon: _vm.css, size: _vm.size } })
        : _vm._e(),
      _vm._v(" "),
      !_vm.fa ? _c("i", { class: _vm.css }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }