var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-modality",
    {
      ref: "myRef",
      attrs: { title: "", "hide-footer": true, width: "900px", centered: "" },
      on: { hide: _vm.close },
    },
    [
      _vm.post
        ? _c(
            "div",
            { staticClass: "sb-modal-post" },
            [
              _vm.post.social_type == "YouTube"
                ? _c("you-tube", { attrs: { post: _vm.post } })
                : _c("div", [
                    _vm.post.img_src !== ""
                      ? _c(
                          "div",
                          { staticClass: "photo" },
                          [
                            _c(
                              "a",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.showEmbedVideo,
                                    expression: "!showEmbedVideo",
                                  },
                                ],
                                staticClass: "sb-photo-link",
                                attrs: { href: _vm.post.url },
                                on: _vm._d({}, [
                                  _vm.openInDialogEvent,
                                  function ($event) {
                                    $event.preventDefault()
                                    return _vm.handler()
                                  },
                                ]),
                              },
                              [
                                _vm.post.social_type == "Vimeo" ||
                                _vm.post.social_type == "TikTok" ||
                                _vm.post.video_src
                                  ? _c(
                                      "div",
                                      { staticClass: "sb-video-icon" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: ["fas", "play"] },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("img", {
                                  staticClass: "sb-modal-image",
                                  attrs: {
                                    src: _vm.post.img_src,
                                    alt: _vm.post.img_alt,
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showEmbedVideo &&
                            _vm.post.social_type == "Vimeo"
                              ? _c("vimeo", { attrs: { post: _vm.post } })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.showEmbedVideo && _vm.post.video_src,
                                    expression:
                                      "showEmbedVideo && post.video_src",
                                  },
                                ],
                              },
                              [
                                _c("video", {
                                  ref: "video-" + _vm.post.id,
                                  attrs: {
                                    playsinline: "",
                                    src: _vm.post.video_src,
                                    type: "video/mp4",
                                  },
                                  on: { click: _vm.playVideo },
                                }),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "sb-modal-post-body-no-photo" },
                          [
                            _c("a", { attrs: { href: _vm.post.url } }, [
                              _vm.post.title
                                ? _c("strong", [_vm._v(_vm._s(_vm.post.title))])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "sb-post-description",
                                domProps: {
                                  innerHTML: _vm._s(_vm.post.description),
                                },
                              }),
                            ]),
                          ]
                        ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "sb-content" }, [
                _c("div", { staticClass: "sb-modal-author-container" }, [
                  _vm.post.author_img_src
                    ? _c("div", { staticClass: "sb-modal-avatar" }, [
                        _vm.post.author_img_src
                          ? _c("img", {
                              attrs: { src: _vm.post.author_img_src },
                            })
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "sb-modal-author",
                      class: {
                        "sb-no-profile-image": !_vm.post.author_img_src,
                      },
                    },
                    [
                      _c("a", { attrs: { href: _vm.post.author_url } }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.post.author) +
                            "\n              "
                        ),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.post.img_src !== ""
                  ? _c("div", { staticClass: "sb-modal-post-body" }, [
                      _c("a", { attrs: { href: _vm.post.url } }, [
                        _vm.post.title
                          ? _c("strong", [_vm._v(_vm._s(_vm.post.title))])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "sb-post-description",
                          domProps: { innerHTML: _vm._s(_vm.post.description) },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "sb-modal-view" }, [
                  _c(
                    "a",
                    {
                      staticClass: "sb-channel-button",
                      attrs: { href: _vm.post.url },
                    },
                    [
                      _c("icon", {
                        attrs: { channel: _vm.post.social_type, size: "2x" },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "sb-modal-activity" }, [
                  _c("ul", { staticClass: "sb-modal-meta-list" }, [
                    _vm.post.likes > 0
                      ? _c(
                          "li",
                          { staticClass: "likes" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "thumbs-up"] },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  "aria-label": "Likes",
                                  href: _vm.post.url,
                                },
                              },
                              [_vm._v(_vm._s(_vm.post.likes))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.post.comments > 0
                      ? _c(
                          "li",
                          { staticClass: "comments" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "comments"] },
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: {
                                  "aria-label": "Comments",
                                  href: _vm.post.url,
                                },
                              },
                              [_vm._v(_vm._s(_vm.post.comments))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm.post.published
                  ? _c(
                      "div",
                      { staticClass: "sb-modal-published" },
                      [
                        _c("timeago", {
                          attrs: { datetime: _vm.post.published },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "sb-navigation" }, [
                  _vm.navigation.prev
                    ? _c(
                        "a",
                        {
                          staticClass: "sb-navigation-button",
                          on: { click: _vm.prev },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "chevron-left"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.navigation.next
                    ? _c(
                        "a",
                        {
                          staticClass: "sb-navigation-button",
                          on: { click: _vm.next },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: ["fas", "chevron-right"] },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }