const SET_NAVIGATION = (state, payload) => 
{
    state.navigation = payload
}

const SET_POST = (state, payload) => 
{
    state.post = payload
}

const SET_FEED = (state, payload) => {
      console.log("SET_FEED", payload)
      //state.feed = payload
      state.properties = payload.properties
      state.organisation = payload.organisation
      state.sources = payload.sources
      state.filter_sources = payload.filter_sources
      state.posts = payload.posts
      state.social_connections = payload.social_connections
      state.pagination = payload.pagination
    }

const SET_PAGINATION = (state, payload) => 
{
    state.pagination = payload
}

const SET_CURRENT_PAGE = (state, payload) => 
{
    state.pagination.current_page = payload.page
}

const SET_ERRORS = (state, payload) =>
{
    console.log("SET_ERRORS", payload)
    state.feed.errors = payload
}

const SET_SOURCES = (state, payload) =>
{
    console.log("SET_SOURCES", payload)
    state.sources = payload
}

const SET_SOURCE = (state, payload) =>
{
    console.log("SET_SOURCE", state.sources, payload)
    const item = state.sources.find(item => item.id === payload.id);
    if(item)
    {
        Object.assign(item, payload);
    }
}

const RESET_POSTS = (state, payload) =>
{
    console.log("RESET_POSTS", payload)
    state.posts = payload  
}

const SET_POSTS = (state, payload) =>
{
    console.log("SET_POSTS", payload)
    console.log("state.pagination.current_page", state.pagination.current_page)
    if(state.pagination.current_page == 1)
    {
        state.posts = []
    }
    state.posts.push(...payload)
}

const DELETE_POST = (state, post) =>
{
    console.log("DELETE_POST", post)
    let index = state.posts.indexOf(post)
    state.posts.splice(index, 1)
}

const TOGGLE_MODERATION = (state) =>
{
    console.log("TOGGLE_MODERATION")
    state.properties.moderation = !state.properties.moderation
}

export default{
    SET_FEED, 
    SET_ERRORS,
    SET_SOURCES,
    SET_SOURCE, 
    SET_POSTS,
    RESET_POSTS,
    SET_PAGINATION,
    SET_CURRENT_PAGE,
    SET_NAVIGATION,
    SET_POST,
    DELETE_POST,
    TOGGLE_MODERATION
}