<template>        
    <div class="sb-post">
        <article>
            <section>
                <div class="sb-post-photo" 
                    :style="{backgroundImage: 'url(' + post.img_src + ')'}">
                    <a v-if="!showEmbedVideo" 
                       :href="post.url" 
                       class="sb-photo-link"
                       @[openInDialogEvent].prevent="handler()">

                        <div class="sb-video-icon" 
                            v-if="post.social_type == 'Vimeo' || post.video_src">
                            <font-awesome-icon :icon="['fas', 'play']" /> 
                        </div>

                        <div class="sb-post-content">
                            <div class="sb-post-body" v-if="properties.show_description && (post.description || post.title)">
                                <strong v-if="post.title">{{post.title}}</strong> 
                                <div class="sb-post-description" v-html="post.description"></div>
                            </div>

                            <div v-if="post.published" class="sb-post-published">
                                <timeago :datetime="post.published"></timeago>
                            </div>

                            <ul class="sb-meta-list" v-if="properties.show_meta">
                                 <li class="sb-likes" v-if="post.likes>0">
                                    <i class="far fa-thumbs-up" aria-hidden="true"></i>{{post.likes}}
                                </li>
                                <li class="sb-comments" v-if="post.comments>0">
                                    <i class="far fa-comments" aria-hidden="true"></i> {{post.comments}}
                                </li>
                            </ul>
                        </div>
                    </a>
                    <vimeo v-if="showEmbedVideo && post.social_type == 'Vimeo'" :post="post"></vimeo>
                    <div v-show="showEmbedVideo && post.video_src">
                        <video :ref="'video-' + post.id" 
                               playsinline 
                               :src="post.video_src" 
                               @click="playVideo"
                               type='video/mp4'></video>
                    </div>
                </div>
            </section> 
            <footer v-if="post.social_type">
                <div class="sb-footer-container">
                    <ul class="sb-meta-list">
                        <li>
                            <icon :channel="post.social_type" :size="'lg'" />
                        </li>   
                        <li>
                             <a :href="post.author_url">
                                <span class="sb-author">{{post.author}}</span>
                            </a>
                        </li>s
                    </ul> 
                </div>
            </footer>   
        </article>
    </div>  
</template>
<script>
    import Vue from 'vue'
    import { mapState, mapActions } from 'vuex'
    import VLazyImage from "v-lazy-image"
    import Icon from 'components/shared/icons/icon.vue'
    import Vimeo from 'components/shared/post/video/vimeo.vue'
 
    export default{
        props: ["properties", "post", "lazy"],
        components: { VLazyImage, Icon, Vimeo },
        data() {
            return {
                showEmbedVideo: false,
            }
        },
        ...mapState("feed", ["navigation"]),
        computed: {
           openInDialogEvent: function() {
               if(this.properties.open_posts_in_dialog == 1) 
               {
                   return "click"
               }
               if(this.post.social_type == 'Vimeo') 
               {
                   return "click"
               }
               if(this.post.video_src) 
               {
                   return "click"
               }
               return null
           }
        },
        methods: {
            ...mapActions("feed", ['setNavigation']),
            handler() {
                if(this.properties.open_posts_in_dialog == 1) 
                {
                    this.setNavigation(this.post)
                    return;
                }
                if(this.post.social_type == 'Vimeo') 
                {
                    this.showEmbedVideo = true
                    return;
                }
                if(this.post.video_src) 
                {
                    this.showEmbedVideo = true
                    let video = this.$refs['video-' + this.post.id]
                    video.play();
                    return;
                }
            },
            playVideo() {
                let video = this.$refs['video-' + this.post.id]
                if(!video.paused)
                {
                    video.pause()
                }
                else
                {
                    video.play()
                }
            }
        }
    }
</script>