var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.properties.custom_css
        ? _c("style", { tag: "component" }, [
            _vm._v("\n        " + _vm._s(_vm.properties.custom_css) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "sb-feed-container",
          class: "sb-" + _vm.properties.theme,
          style: _vm.feedContainerStyles,
        },
        [
          _vm.filter_sources.length > 0 &&
          _vm.properties.filters !== "" &&
          _vm.properties.filters !== "off" &&
          !_vm.properties.moderation
            ? _c("filters", { on: { filterPosts: _vm.filterPosts } })
            : _vm._e(),
          _vm._v(" "),
          _vm.sources.length > 0 && _vm.posts.length == 0
            ? _c("empty")
            : _vm._e(),
          _vm._v(" "),
          _vm.columns
            ? _c(
                "div",
                { staticClass: "sb-posts-container" },
                _vm._l(_vm.columns, function (column) {
                  return _c(
                    "div",
                    {
                      key: column,
                      class: "sb-columns sb-columns" + _vm.columns,
                    },
                    [
                      _vm._l(_vm.structuredPosts[column - 1], function (post) {
                        return [
                          _c("post", { key: post.id, attrs: { post: post } }),
                        ]
                      }),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("modal"),
          _vm._v(" "),
          _vm.properties.infinite_scroll == 1 && _vm.posts.length > 0
            ? _c(
                "infinite-loading",
                { on: { infinite: _vm.infiniteHandler } },
                [_c("span", { attrs: { slot: "no-more" }, slot: "no-more" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.properties.infinite_scroll == 0 &&
          _vm.posts.length > 0 &&
          _vm.pagination.current_page < _vm.pagination.total_pages
            ? _c("load-more", { on: { loadMore: _vm.loadMore } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }