import Vue from 'vue'
import { store } from './store'
import feed from 'components/public/index.vue'
import VueTimeago from 'vue-timeago'
    
Vue.use(VueTimeago, {
    name: 'Timeago', 
    locale: 'en',
    // https://github.com/egoist/vue-timeago/issues/94
    converter(date, locale, { includeSeconds, addSuffix = true }) {
        const distanceInWordsStrict = require('date-fns/distance_in_words_strict')
        return distanceInWordsStrict(Date.now(), date, { locale, addSuffix, includeSeconds });
    }
})

new Vue({
    el: '#socialblend',
    store,
    components: { 'feed' : feed },
})