var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sb-feed-container" },
    [
      _c("instagram-icon"),
      _vm._v(" "),
      _vm.loaded && _vm.posts.length == 0 ? _c("no-posts") : _vm._e(),
      _vm._v(" "),
      _vm.loaded && _vm.posts.length > 0 ? _c("posts") : _vm._e(),
      _vm._v(" "),
      !_vm.loaded ? _c("loading") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }