var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "noposts-container" }, [
    _c("p", { staticClass: "lead" }, [_vm._v("You feed is empty.")]),
    _vm._v(" "),
    _c("p", [
      _vm._v("Please add some sources in the "),
      _c(
        "a",
        { attrs: { href: _vm.webHost() + "/dashboard", target: "_blank" } },
        [_vm._v("dashboard")]
      ),
      _vm._v("."),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }