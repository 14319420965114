<template>  
    <div>    
        <component is="style" v-if="properties.custom_css">
            {{properties.custom_css}}
        </component>

        <div class="sb-feed-container" :class="'sb-' + properties.theme" :style="feedContainerStyles">
    
                <filters v-if="filter_sources.length > 0 && properties.filters !== '' && properties.filters !== 'off' && !properties.moderation"
                         @filterPosts="filterPosts" />
                <empty v-if="sources.length > 0 && posts.length==0" />

                <div class="sb-posts-container" v-if="columns">
                    <div :class="'sb-columns sb-columns'+ columns"
                     v-for="column in columns" 
                     :key="column">
                        <template v-for="post in structuredPosts[column-1]">
                            <post 
                                v-bind:key="post.id"
                                :post="post" 
                            />
                        </template>
                    </div>
                </div>
            <modal />
            <infinite-loading v-if="properties.infinite_scroll==1 && posts.length > 0" 
                              @infinite="infiniteHandler">
                <span slot="no-more"></span>
            </infinite-loading>
            <load-more v-if="properties.infinite_scroll==0 && posts.length > 0 && pagination.current_page < pagination.total_pages" 
                       @loadMore="loadMore" /> 
        </div>
    </div>       
</template>
<script>
    import 'styles/themes.css'
    import Vue from 'vue'
    import Post from './post.vue'
    import Filters from 'components/shared/posts/filters.vue'
    import LoadMore from 'components/shared/posts/loadmore.vue'
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex' 
    import InfiniteLoading from 'vue-infinite-loading'
    import utils from 'utils'
    import Empty from 'components/shared/posts/empty.vue'
    import Modal from 'components/shared/post/modal.vue'

    export default{
        props: [],
        data() {
            return {
                filter: "",
                page: 1,
                icons: {
                        'deviantart': utils.faClasses('deviantart'),
                        'facebook': utils.faClasses('facebook'),
                        'flickr': utils.faClasses('flickr'),
                        'giphy': utils.faClasses('giphy'),
                        'instagram': utils.faClasses('instagram'),
                        'linkedin': utils.faClasses('linkedin'),
                        'pinterest': utils.faClasses('pinterest'),
                        'rss': utils.faClasses('rss'),
                        'slack': utils.faClasses('slack'),
                        'soundcloud': utils.faClasses('soundcloud'),
                        'spotify': utils.faClasses('spotify'),
                        'tiktok': utils.faClasses('tiktok'),
                        'tumblr': utils.faClasses('tumblr'),
                        'twitter': utils.faClasses('twitter'),
                        'vimeo': utils.faClasses('vimeo'),
                        'yelp': utils.faClasses('yelp'),
                        'youTube': utils.faClasses('youtube'),
                }
            }
        },
        computed: {
           ...mapState("feed", ["properties", "sources", "filter_sources", "pagination", "posts", "navigation"]),
           feedContainerStyles: function() {
               let styles = {}
               if(this.properties.height) { styles.height = this.properties.height + "px" }
               if(this.properties.width) { styles.width =  this.properties.width + "px" }
               return styles
           },
           structuredPosts: function() {
               let y = []   
               if(this.posts) { 
               //    y = [ ...Array(this.posts.length).keys() ].map( i => i+1) 
               }
               return utils.splitPostsIntoColumns(this.posts, this.columns)
           },
           columns: function() {
            return utils.calcColumns(this.windowWidth, this.properties)
           }
        },
        components: {
            Post,
            Modal,
            Filters,
            Empty,
            InfiniteLoading,
            LoadMore
        },
        methods: {
            ...mapActions("feed", ['getFeedPosts']),
            loadMore(){
                let page = this.pagination.current_page + 1
                let payload = {
                                page: page, 
                                filter_type: this.properties.filters,
                                filter: this.filter
                              }
                console.log("loadMore")
                this.getFeedPosts(payload)
            },
            infiniteHandler($state) {
                this.page = this.page + 1
                let payload = {
                                page: this.page, 
                                filter_type: this.properties.filters,
                                filter: this.filter,
                              }

                this.getFeedPosts(payload).then(() => {
                    console.log("pages", 
                                this.page)
                    if(this.page < this.pagination.total_pages)
                    {
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                });
            },
            filterPosts(val) { 
                this.filter = val 
                let page = 1
                let payload = {
                                page: page, 
                                filters: this.properties.filters,
                                filter_value: val,
                              }
                console.log("filterPosts", payload)
                this.getFeedPosts(payload)
            }
        }
    }
</script>