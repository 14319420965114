
import Vue from 'vue'
import VueWindowSize from 'vue-window-size'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComments, faThumbsUp } from '@fortawesome/free-regular-svg-icons'
import { faRss, faCheck, faChevronRight, faChevronLeft, faPlay, faSpinner } from '@fortawesome/free-solid-svg-icons'
import {  faDeviantart, 
          faFacebook,
          faFlickr,
          faInstagram,
          faLinkedin,
          faMedium,
          faPinterest,
          faSlack,
          faSoundcloud,
          faSpotify,
          faTiktok,
          faTumblr,
          faTwitter,
          faVimeo,
          faYelp,
          faYoutube
        } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faThumbsUp,
            faCheck,
            faComments,
            faRss,
            faChevronRight,
            faChevronLeft,
            faPlay,
            faSpinner,
            faDeviantart, 
            faFacebook,
            faFlickr,
            faInstagram,
            faLinkedin,
            faMedium,
            faPinterest,
            faSlack,
            faSoundcloud,
            faSpotify,
            faTiktok,
            faTumblr,
            faTwitter,
            faVimeo ,
            faYelp,
            faYoutube)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueWindowSize)

var faClasses = (channel) => {
    if(!channel) return ""
    channel = channel.toLowerCase().trim()

    if(channel == "giphy")
    {
      return ["custom", channel]
    }

    let css = []
    css.push(channel === "rss" ? "fas" : "fab")
    css.push(channel)
    return css
  }

var calcColumns = (width, properties) => { 

    // https://getbootstrap.com/docs/4.6/layout/overview/#responsive-breakpoints
    // https://getbootstrap.com/docs/5.2/layout/breakpoints/

    if(width < 576) { // sm
        return parseInt(properties.columns_sm)
    }  
    if(width < 768) { // md
        return parseInt(properties.columns_md)
    }
    if(width < 1200) { // lg
       return parseInt(properties.columns_lg)
    }
    if(width > 1201) { // xl
        return parseInt(properties.columns_xl)
    }
    // xs
    return parseInt(properties.columns_xs)
}

var splitPostsIntoColumns = (posts, columns) => {
  // TODO replace the array with the posts
  let t = posts.reduce(function (acc, curr, idx) {
  acc[idx % columns] ? acc[idx % columns].push(curr) : acc[idx % columns] = [curr];
  return acc
  }, []);
  return t
}

const isNumeric = (num) => (typeof(num) === 'number' || typeof(num) === "string" && num.trim() !== '') && !isNaN(num)

const isProduction = () => { 
  return !Vue.config.devtools;
}

var webHost = () => {
  return "https://socialblend.com";
  return isProduction() ? "https://socialblend.com" : "http://socialblend.test"
}

var apiHost = () => {
  return "https://api.socialblend.com";
  return isProduction() ? "https://api.socialblend.com" : "http://socialblend.test"
}

var appHost = () => {
  return "https://app.socialblend.com";
  return isProduction() ? "https://app.socialblend.com" : "http://socialblend.test"
}

var cdnHost = () => {
  return "https://cdn.socialblend.com";
  return isProduction() ? "https://cdn.socialblend.com" : "http://socialblend.test"
}

class PostModeration {
  static get DeletedManually() { return 1 }
  static get DeletedByRule() { return 2 }
  static get FilteredByProfanity() { return 3 }
  static get FilteredByRule() { return 4 }
  static get PublishedByRule() { return 5 }
  static get PublishedAutomatically() { return 6 }
  static get PublishedManually() { return 7 }
  static get DeletedConfirmed() { return 9 }
}

export default 
{ 
  PostModeration,
  isNumeric,
  webHost,
  apiHost,
  appHost,
  cdnHost,
  faClasses, 
  calcColumns,
  splitPostsIntoColumns 
}