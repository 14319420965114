<template>
  <svg width="0" height="0">
    <radialGradient id="rg" r="150%" cx="30%" cy="107%">
      <stop stop-color="#fdf497" offset="0" />
      <stop stop-color="#fdf497" offset="0.05" />
      <stop stop-color="#fd5949" offset="0.45" />
      <stop stop-color="#d6249f" offset="0.6" />
      <stop stop-color="#285AEB" offset="0.9" />
    </radialGradient>
  </svg>
</template>
<script>
    // https://stackoverflow.com/questions/47800574/gradient-over-instagram-svg-of-fontawesome-5/47801536#47801536
    export default{}
</script>