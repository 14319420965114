<template>          
    <div class="sb-more-container">
        {{more}}
        <button class="sb-more-button" @click="loadMore()">More</button>
    </div>          
</template>
<style scoped>

</style>
<script>
    import Vue from 'vue'
    import { mapState } from 'vuex' 

    export default{
        props: ["more"],
        computed: {
           ...mapState("feed", ["properties"]),
        },
        methods:{
            loadMore: function() {
                this.$emit('loadMore')
            }
        }
    }
</script>