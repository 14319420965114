var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vimeo-player",
    _vm._b(
      { ref: "vimeo", on: { ready: _vm.onReady } },
      "vimeo-player",
      _vm.playerOptions,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }