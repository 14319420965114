const properties = state => state.properties
const sources = state => state.sources
const posts = state => state.posts
const organisation = state => state.organisation
const pagination = state => state.pagination
const social_connections = state => state.social_connections

export default{
    properties, 
    sources,
    posts,
    organisation,
    social_connections,
    pagination
}