<template>
    <div class="sb-feed-container">
        <instagram-icon />
        <no-posts v-if="loaded && posts.length==0" />
        <posts v-if="loaded && posts.length>0" />
        <loading v-if="!loaded" />
    </div>
</template>
<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
    import InstagramIcon from 'components/shared/icons/instagram.vue'
    import NoPosts from './index/no-posts.vue'
    import Posts from './index/posts.vue'
    import Loading from 'components/shared/posts/loading2.vue'

    export default{
        props: ['id', 
                'page_size', 'max_pages', 
                'start', 'end',
                'filters', 'filter_value', 
                'theme', 'trim_description', 
                'script_after_render'],
        components: {
            InstagramIcon,
            Posts,
            Loading,
            NoPosts
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapState("feed", ["posts", "sources", "organisation"]),
            loaded: function() {
               return Object.entries(this.organisation).length > 0
           },
        },
        created: function () {  
            let self = this
            let payload = {
                id: this.id,
                page_size: this.page_size, 
                max_pages: this.max_pages,
                start: this.start, 
                end: this.end,
                filters: this.filters, 
                filter_value: this.filter_value, 
                theme: this.theme, 
                trim_description: this.trim_description
            }

            console.log("payload ", payload)
            // this.getFeed(payload)
            this.getFeed(payload).then(() => {
                                               
                                               this.loaded = true

                                                setTimeout(() => {   
                                                    let pollInterval = this.organisation.plan.poll_interval * 1000
                                                    if(pollInterval)
                                                    {
                                                        setInterval(function() { 
                                                            self.getFeed(payload)
                                                        }, pollInterval);
                                                    }
                                            }, 5000);
           })
            
            // TODO JS function to run after render
            //if (typeof this.script_after_render == 'function') { 
               // go2(); 
            // }

        },
        methods:
        { 
            ...mapActions("feed", ['getFeed']),
        }
    }
</script>