<template>        
    <div class="sb-post" v-if="post.img_src">
        <article>
            <section>
                <div class="sb-post-photo">
                    <a v-if="!showEmbedVideo" 
                       :href="post.url" 
                       class="sb-photo-link"
                       @[openInDialogEvent].prevent="handler()">
                        <div class="sb-video-icon" 
                            v-if="post.social_type == 'Vimeo' || post.video_src">
                            <font-awesome-icon :icon="['fas', 'play']" /> 
                        </div>
                        <img v-if="!lazy"
                            :src="post.img_src" 
                            :alt="post.img_alt" 
                            class="post-image not-so-lazy" />
                        <v-lazy-image v-if="lazy && post.img_src" 
                                    :src="post.img_src" 
                                    class="post-image lazy" />
                     </a>
                     <vimeo v-if="showEmbedVideo && post.social_type == 'Vimeo'" :post="post"></vimeo>
                     <div v-show="showEmbedVideo && post.video_src">
                        <video :ref="'video-' + post.id" 
                               playsinline 
                               :src="post.video_src" 
                               @click="playVideo"
                               type='video/mp4'></video>
                     </div>
                </div>
            </section>
        </article>
    </div>  
</template>
<script>
    import Vue from 'vue'
    import { mapState, mapActions } from 'vuex'
    import VLazyImage from "v-lazy-image"
    import Vimeo from 'components/shared/post/video/vimeo.vue'
 
    export default{
        props: ["properties", "post", "lazy"],
        components: { VLazyImage, Vimeo },
        data() {
            return {
                showEmbedVideo: false,
            }
        },
        computed: {
           openInDialogEvent: function() {
               if(this.properties.open_posts_in_dialog == 1) 
               {
                   return "click"
               }
               if(this.post.social_type == 'Vimeo') 
               {
                   return "click"
               }
               if(this.post.video_src) 
               {
                   return "click"
               }
               return null
           }
        },
        methods: {
            ...mapActions("feed", ['setNavigation']),
            handler() {
                if(this.properties.open_posts_in_dialog == 1) 
                {
                    this.setNavigation(this.post)
                    return;
                }
                if(this.post.social_type == 'Vimeo') 
                {
                    this.showEmbedVideo = true
                    return;
                }
                if(this.post.video_src) 
                {
                    this.showEmbedVideo = true
                    let video = this.$refs['video-' + this.post.id]
                    video.play();
                    return;
                }
            },
            playVideo() {
                let video = this.$refs['video-' + this.post.id]
                if(!video.paused)
                {
                    video.pause()
                }
                else
                {
                    video.play()
                }
            }
        }
    }
</script>